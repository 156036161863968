import { Component } from '@angular/core';
import { LazyLoadScriptService } from './lazy-load-script/lazy-load-script.service';
declare var google: any;

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	constructor(private lazyLoadService: LazyLoadScriptService) {
		this.lazyLoadService.loadStyle('bootstrap.css');
		this.lazyLoadService.loadStyle('toastr.css');
		this.lazyLoadService.loadStyle('styles.css');
		this.lazyLoadService.loadStyle('styles1.css');
		this.lazyLoadService.loadStyle('font-awesome-min.css');
		this.lazyLoadService.loadStyle('font-awesome.css');
		this.lazyLoadService.loadStyle('feather.css');
		this.lazyLoadService.loadStyle('summernote.css');
		this.lazyLoadService.loadStyle('ng-select-default-theme.css');
		google.load("visualization", "1", { packages: ["corechart","line","bar"] });
	}
}
